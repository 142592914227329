import { castDateTimeFilters, DateTimeFiltersRequest } from '../../utils/requestHelper';
import { done, failed, RemoteData } from '../../utils/remote-data';
import { get } from './base';
import { Surveys } from '../../interfaces/survey';

export const getSurveys = async (
  patientId: string,
  dateFilters: DateTimeFiltersRequest,
): Promise<RemoteData<Surveys>> => {
  try {
    const res = await get(`/survey-responses?patientId=${patientId}`, {
      params: {
        ...castDateTimeFilters(dateFilters),
      },
    });
    return done(res.data);
  } catch (err) {
    return failed(err);
  }
};
