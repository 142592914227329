import { DeleteIcon } from 'assets/icons/permissions';
import Table from 'components/common/table/table';
import { getPatientTimezone } from 'contexts/PatientContext';
import { QuestionnaireSchedule } from 'graphql/codegen/schemas';
import { Patient } from 'interfaces/patient';
import moment from 'moment';
import * as React from 'react';
import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import { displayTableDateFormat, displayTableDateTimeFormat } from 'utils/data-parser';
import styles from './schedule.table.module.scss';

type Schedule = QuestionnaireSchedule & { id: number };


const SchedulesTable: React.FC<{
  patient: Patient;
  onSelect: (schedule: QuestionnaireSchedule) => void;
  error: any;
  loading: boolean;
  schedules: QuestionnaireSchedule[];
  onDelete: (schedule: QuestionnaireSchedule) => void;
}> = ({ patient, onSelect, error, loading, schedules, onDelete }) => {
  const timezone = getPatientTimezone();
  const bacTableProps: Column<Schedule>[] = useMemo(
    () => [
      {
        accessor: 'created',
        Header: 'DATE',
        width: 200,
        Cell: ({ cell: { value } }: CellProps<Schedule, string>) => displayTableDateTimeFormat(value, timezone),
        sortType: 'basic',
      },
      {
        accessor: v => v.questionnaire?.clinicName ?? v.questionnaireName,
        Header: 'SURVEY',
        width: 200,
        Cell: ({ cell: { value } }) => value,
        sortType: 'basic',
      },
      {
        accessor: v =>
          `${v.repeat.frequencyDescription || ''}${
            moment(v.endDate).year() < 3000 ? `.Ends on ${displayTableDateFormat(v.endDate, timezone)}` : ''
          }`,
        Header: 'SCHEDULE',
        width: 200,
        Cell: ({ cell: { value } }) => value,
        sortType: 'basic',
      },
      {
        accessor: v => v,
        id: 'click-action',
        Header: '',
        width: 50,
        Cell: ({ cell: { value } }) => (
          <button type='button' className={styles.viewButton} onClick={() => onSelect(value)}>
            View
          </button>
        ),
        disableSortBy: true,
      },
      {
        id: 'delete-icon-column',
        minWidth: 36,
        width: 36,
        accessor: v => v,
        Cell: ({ cell: { value, row } }) => (
          <button
            type='button'
            className={styles.deleteBtn}
            onClick={() => {
              onDelete(value);
            }}
          >
            <DeleteIcon />
          </button>
        ),
        disableSortBy: true,
      },
    ],
    [timezone, onSelect, onDelete],
  );
  const defaultSorting: { sortBy: [{ id: string; desc: boolean }] } = useMemo(
    () => ({ sortBy: [{ id: 'created', desc: true }] }),
    [],
  );

  const getTableBodyProps = () => {
    return {
      className: styles.tbody,
      style: {
        height: '564px',
      },
    };
  };

  const getTableProps = () => {
    return {
      className: '', // bacTests.status === 'Done' && !bacTests.data.values.length ? styles.noContent : styles.table,
    };
  };
  return (
    <Table<Schedule>
      getRowId={v => `${v.scheduleId}`}
      data={(schedules as Schedule[]) || []}
      columns={bacTableProps}
      isLoading={loading || !patient}
      error={!!error}
      rowStyles={{ background: 'rgba(65, 126, 185, 0.1)' }}
      getRowProps={row => ({
        key: `${row?.id}`,
        style: {
          background: '#FFF', //row?.original?.identification?.identical ? '#FFF' : 'rgba(65, 126, 185, 0.1)',
        },
        className: styles.tr,
      })}
      getHeaderProps={column => ({
        className: styles.th,
        key: `${column?.id}`,
        style: {
          width: column?.width,
          minWidth: column?.minWidth,
          maxWidth: column?.maxWidth,
        },
      })}
      getCellProps={cell => ({
        key: `${cell?.column.id}`,
        style: {
          justifyContent: cell?.column.id === 'photo' ? 'flex-end' : 'flex-start',
          padding: cell?.column.id === 'location' ? '11px 18px' : undefined,
          display: 'flex',
        },
        className: styles.td,
      })}
      defaultColumn={{ width: 180, minWidth: 50 }}
      initialState={defaultSorting}
      getTableBodyProps={() => getTableBodyProps()}
      getTableProps={() => getTableProps()}
      emptyDataMessage='There are no surveys at this time.'
    />
  );
};

export default SchedulesTable;
